<template>
    <div>
        <a-divider orientation="left">
			Configuraciones
		</a-divider>
        <br>
        <a-tabs 
            default-active-key="1" 
            tab-position="left"
            :destroyInactiveTabPane="false" 
            style="margin-top: -10px;"
            >
            <a-tab-pane key="1">
                <template #tab>
                    Configuraciones
                </template>
                <Configuracion />
            </a-tab-pane>

            <a-tab-pane key="2">
                <template #tab>
                    Parámetros
                </template>
                <Parametrizacion />
            </a-tab-pane>

            <a-tab-pane key="3">
                <template #tab>
                    Marcas
                </template>
                <Marca />
            </a-tab-pane>

            <a-tab-pane key="4">
                <template #tab>
                    Categorias
                </template>
                <Categoria />
            </a-tab-pane>

            <a-tab-pane key="5">
                <template #tab>
                    Unidades
                </template>
                <Unidad />
            </a-tab-pane>
        </a-tabs>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import router from '@/config/router'
    import locale from 'ant-design-vue/es/date-picker/locale/es_ES'
    import moment from 'moment'
    import 'moment/locale/es'
    import * as utils from '@/utils/utils'

    import Marca from '../Marca/Marca.vue'
    import Categoria from '../Categoria/Categoria.vue'
    import Unidad from '../Unidad/Unidad.vue'
    import Configuracion from '../Parametro/Modulo/Configuracion.vue'
    import Parametrizacion from '../Parametro/Modulo/Parametrizacion.vue'
 
    export default {

        components: {
            Marca,
            Categoria,
            Unidad,
            Configuracion,
            Parametrizacion
        },

        data () {
            return {
                dateFormat: 'YYYY-MM-DD',
                utils,
				moment,
				locale
            }
        }
    }
</script>